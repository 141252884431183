global.apiURL = 'https://api.kosign.xyz/index.php/api/';
//global.apiURL = 'http://kosign.localhost/index.php/api/';
//global.pusherAppKey = '0667b61d23b491b8a351';

global.paymentCompleteURL = 'https://kosign.xyz/thankyou';
//global.paymentCompleteURL = 'http://localhost:3000/thankyou';

//global.stripePubKey = 'pk_test_thNMA1k47dvYwQAtl9x3cBkU004UqaXoD7';
global.stripePubKey = 'pk_test_I4rOTLfBxkIH2dqZ1c8iTr2400Pjy6dJbv';
global.costPerKey = 0;
global.setupCost  = 0;
global.freeKeys   = 20;
global.maxCharsPerVaultFree = 5000;
global.maxCharsPerVault = 5000;
//global.regularURL = 'http://xxxxxx.localhost/api/index.php/';

//global.wsURL = 'wss://echo.websocket.org';
//global.wsURL = 'http://xxxx:9000';

